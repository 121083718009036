<template>
  <div class="home mb-5 text-left ">


    <CrAlert v-if="status.responseStatus == 200" severity="success" @close="status.responseStatus = 0">
      <div>Ihr Anfrage wurde erfolgreich gesendet</div>
    </CrAlert>

    <CrAlert v-if="status.responseStatus != 200 && status.responseStatus != 0" severity="error" @close="status.responseStatus = 0">
      <div>Es ist ein unbekannter Fehler aufgetreten</div>
    </CrAlert>



    <div class="row">
      <div class="col-0 col-lg-2"></div>
      <div class="col-12 col-lg-8">

        <h1 class="mt-4 mb-5 text-left cr-title-v4-2-0 cr-title--h1-v4-2-0">
          Projektierung Fernwirk- und Schutzschrank
        </h1>
      <!--<CrTitle title="Projektierung Fernwirk- und Schutzschrank" :heading="1" class="mt-4 mb-5 text-left"></CrTitle>-->
      <!--<div class="col-0 col-lg-2 col-xxl-3"></div>
      <div class="col-12 col-lg-8 col-xxl-6">-->

    <CrTitle title="Projekt" :heading="3"></CrTitle>
    <div class="row">
      <div class="col">
        <CrInput id="Projektname" label="Projektname" v-model="project.project.name"></CrInput>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-8">
        <CrSelect id="Anlagentyp" label="Anlagentyp" v-model="project.project.system_type" :options="anlagenOptions"></CrSelect>
      </div>
      <div class="col-12 col-md-4">
        <CrInput id="Trafoleistung [kVa]" label="Trafoleistung [kVa]" v-model="project.project.travo_power"></CrInput>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-8">
        <CrInput id="Netzgebiet" label="Netzgebiet" v-model="project.project.grid_area"></CrInput>
      </div>
      <div class="col-12 col-md-4">
        <CrInput id="PLZ des Aufbauortes" label="PLZ des Aufbauortes" v-model="project.project.postal_code"></CrInput>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <CrDatePicker type="date" locale="de-DE" id="Späteste Angebotsabgabe" label="Späteste Angebotsabgabe" v-model="project.project.offer_date"></CrDatePicker>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <CrDatePicker type="date" locale="de-DE" id="Realisierung (ungefähr)" label="Realisierung (ungefähr)" v-model="project.project.realisation_date"></CrDatePicker>
      </div>
    </div>


    <CrTitle title="Kunde / Ansprechpartner" :heading="3" class="mt-4"></CrTitle>
    <div class="row">
      <div class="col">
        <CrInput id="Kunde" label="Kunde" v-model="project.customer.company"></CrInput>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <CrInput id="Mein Vorname"  label="Mein Vorname" v-model="project.customer.firstname"></CrInput>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <CrInput id="Mein Nachname" label="Mein Nachname" v-model="project.customer.surname"></CrInput>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <CrInput id="Meine E-mail" label="Meine E-mail" v-model="project.customer.mail"></CrInput>
      </div>
    </div>


        <CrTitle title="Kurzschlussmelder" :heading="3" class="mt-4"></CrTitle>
    <div class="row">
      <div class="col">
        <CrSelect id="Kurzschlussmelder" label="Kurzschlussmelder" v-model="project.short_circuit_indicator.information" :options="kurzschlussmelder"></CrSelect>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <CrSelect id="Kurzschlussmelder Hersteller" label="Hersteller" v-model="project.short_circuit_indicator.manufacturer" :options="hersteller"></CrSelect>
      </div>
      <div class="col-4">
        <CrInput id="Kurzschlussmelder Typ" label="Typ" v-model="project.short_circuit_indicator.system_type"></CrInput>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <CrSelect id="Kurzschlussmelder Kommunikationsprotokoll" label="Kommunikationsprotokoll" v-model="project.short_circuit_indicator.protocol" :options="kommunikationsprotokoll"></CrSelect>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <CrSelect id="LS-Ansteuerung" label="LS-Ansteuerung" v-model="project.short_circuit_indicator.control" :options="lsAnsteuerung"></CrSelect>
      </div>
    </div>

    <CrTitle title="EZA-Regler" :heading="3" class="mt-4"></CrTitle>
    <div class="row">
      <div class="col">
        <CrSelect id="EZA-Regler" label="EZA-Regler" v-model="project.eza.information" :options="ezaRegler"></CrSelect>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <CrInput id="EZA-Regler Hersteller" label="Hersteller" v-model="project.eza.manufacturer"></CrInput>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <CrInput id="EZA-Regler Typ" label="Typ" v-model="project.eza.type"></CrInput>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <CrSelect id="EZA-Regler Kommunikationsprotokoll" label="Kommunikationsprotokoll" v-model="project.eza.protocol" :options="kommunikationsprotokoll"></CrSelect>
      </div>
    </div>


    <CrTitle title="Hilfsspannung 24VDC" :heading="3" class="mt-4"></CrTitle>
    <div class="row">
      <div class="col">
        <CrSelect id="Hilfsspannung 24VDC" label="Hilfsspannung 24VDC" v-model="project.auxiliary_power.information" :options="hilfsSpannung"></CrSelect>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <CrInput id="Gewünschte Pufferungszeit [h]" label="Gewünschte Pufferungszeit [h]" type="number" v-model="project.auxiliary_power.buffer_time"></CrInput>
      </div>
    </div>


    <CrTitle title="Niederspannungsmessung" :heading="3" class="mt-4"></CrTitle>
    <div class="row">
      <div class="col">
        <CrSelect id="Niederspannungsmessung" label="Niederspannungsmessung" v-model="project.low_voltage_measurement.information" :options="ezaRegler"></CrSelect>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <CrInput id="Niederspannungsmessung Hersteller" label="Hersteller" v-model="project.low_voltage_measurement.manufacturer"></CrInput>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <CrSelect id="Niederspannungsmessung Typ" label="Typ" v-model="project.low_voltage_measurement.type" :options="typ" class="text-left"></CrSelect>
      </div>
    </div>

    <CrTitle title="EZA-Schutzeinrichtung" :heading="3" class="mt-4"></CrTitle>
    <div class="row">
      <div class="col">
        <CrSelect id="EZA-Schutzeinrichtung" label="EZA-Schutzeinrichtung" v-model="project.eza_protector.information" :options="ezaSchutzeinrichtung"></CrSelect>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-3 text-left pt-0">Schutzfunktion</div>
      <div class="col-2 text-left">
        <CrCheckbox id="SchutzfunktionUf" v-model="project.eza_protector.protective_function.uf">U/f</CrCheckbox>
      </div>
      <div class="col-2 text-left">
        <CrCheckbox id="SchutzfunktionUMZ" v-model="project.eza_protector.protective_function.umz">UMZ</CrCheckbox>
      </div>
      <div class="col-2 text-left">
        <CrCheckbox id="SchutzfunktionQU" v-model="project.eza_protector.protective_function.qu">QU</CrCheckbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <CrSelect id="Einbauort" label="Einbauort" v-model="project.eza_protector.location" :options="einbauort" class="text-left"></CrSelect>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <CrInput id="Hersteller" label="Hersteller" v-model="project.eza_protector.manufacturer"></CrInput>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <CrInput id="Typ" label="Typ" v-model="project.eza_protector.type"></CrInput>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <CrSelect id="Kommunikationsprotokoll" label="Kommunikationsprotokoll" v-model="project.eza_protector.protocol" :options="kommunikationsprotokoll2" class="text-left"></CrSelect>
      </div>
    </div>



    <CrTitle title="Anbindung an die Netzleitstelle" :heading="3" class="mt-4"></CrTitle>
    <div class="row">
      <div class="col">
        <CrSelect id="Modem / Gateway" label="Modem / Gateway" v-model="project.grid_control_center.modem" :options="modem" class="text-left"></CrSelect>
      </div>
    </div>
    <div class="row">
      <div class="col-8">
        <CrInput id="Vorgabe Hersteller" label="Vorgabe Hersteller" v-model="project.grid_control_center.manufacturer"></CrInput>
      </div>
      <div class="col-4">
        <CrInput id="Vorgabe Typ" label="Vorgabe Typ" v-model="project.grid_control_center.type"></CrInput>
      </div>
    </div>

    <div class="table-responsive">
      <CrTable class="text-left mt-5 table">
        <CrTableHead>
          <tr>
            <th scope="col">Dienstleistungen</th>
            <th scope="col">Parametrierung</th>
            <th scope="col">Inbetriebnahme</th>
          </tr>
        </CrTableHead>
        <CrTableBody>
          <tr>
            <td>Schutzgerät EZA</td>
            <td><CrCheckbox id="ezaParameter" v-model="project.services.eza.parameterisation"></CrCheckbox></td>
            <td><CrCheckbox id="ezaCommision" v-model="project.services.eza.commissioning"></CrCheckbox></td>
          </tr>
          <tr>
            <td>Schutzgerät EZE</td>
            <td><CrCheckbox id="ezeParameter" v-model="project.services.eze.parameterisation"></CrCheckbox></td>
            <td><CrCheckbox id="ezeCommision" v-model="project.services.eze.commissioning"></CrCheckbox></td>
          </tr>
          <tr>
            <td>Kurzschlussmelder</td>
            <td><CrCheckbox id="shortCircuitParameter" v-model="project.services.short_circuit_indicator.parameterisation"></CrCheckbox></td>
            <td><CrCheckbox id="shortCircuitCommision" v-model="project.services.short_circuit_indicator.commissioning"></CrCheckbox></td>
          </tr>
          <tr>
            <td>Fernwirktechnik</td>
            <td><CrCheckbox id="telecontrolParameter" v-model="project.services.telecontrol.parameterisation"></CrCheckbox></td>
            <td><CrCheckbox id="telecontrolCommision" v-model="project.services.telecontrol.commissioning"></CrCheckbox></td>
          </tr>
          <tr>
            <td>EZA-Regler</td>
            <td><CrCheckbox id="ezaRegulatorParameter" v-model="project.services.eza_regulator.parameterisation"></CrCheckbox></td>
            <td><CrCheckbox id="ezaRegulatorCommision" v-model="project.services.eza_regulator.commissioning"></CrCheckbox></td>
          </tr>
          <tr>
            <td>Strom- & Spannungswandler </td>
            <td><CrCheckbox id="converterParameter" v-model="project.services.converter.parameterisation"></CrCheckbox></td>
            <td><CrCheckbox id="converterCommision" v-model="project.services.converter.commissioning"></CrCheckbox></td>
          </tr>
        </CrTableBody>
      </CrTable>
    </div>


    <div class="row mt-5">
      <div class="col">
        <CrSelect id="Hardwarelieferung" label="Hardwarelieferung" v-model="project.delivery" :options="hardwarelieferung"></CrSelect>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <CrTextArea id="Meine weiteren Wünsche" label="Meine weiteren Wünsche" v-model="project.miscellaneous"></CrTextArea>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <CrCheckbox id="privacy" v-model="privacy">
          <span>
            Ich habe die Informationen zum Datenschutz hinsichtlich der Verarbeitung meiner personenbezogenen Daten zur Kenntnis genommen.
            <a class="cr-anchor-v4-2-0" href="https://www.phoenixcontact.com/de-de/datenschutz" target="_blank" id="cu-privacy">
              Datenschutzerklärung
            </a><span>|</span><a class="cr-anchor-v4-2-0" href="https://www.phoenixcontact.com/de-de/rechtliche-hinweise" target="_blank" id="cu-terms">
              Geschäftsbedingungen
            </a></span>
        </CrCheckbox>
        <CrCheckbox id="contact" v-model="contact">
          Hiermit erteile ich Phoenix Contact die Erlaubnis, mich zu kontaktieren
        </CrCheckbox>
      </div>
    </div>

      <div class="row mt-5">
        <div class="col-12 text-right">
          <CrButton id="Anfrage senden" @click="send" :disabled="!contact || !privacy || status.send" variant="primary">
            <div class="submit-btn">
              <CrLoadingSpinner v-if="status.send"></CrLoadingSpinner>
              <span v-if="!status.send">Anfrage senden</span>
              <span v-else>Ihr Anfrage wird gesendet</span>
            </div>
          </CrButton>
        </div>
      </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Provide, Vue} from 'vue-property-decorator';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import { CrInput, CrSelect, CrDatePicker, CrCheckbox, CrTable, CrTableBody, CrTableHead, CrTitle, CrTextArea, CrButton, CrAlert, CrLoadingSpinner } from "@pxc/crisp-oss-ui-components";

@Component({
  components: {
    HelloWorld,
    CrInput, CrSelect, CrDatePicker, CrCheckbox, CrTable, CrTableBody, CrTableHead, CrTitle, CrTextArea, CrButton, CrAlert, CrLoadingSpinner
  },

  data() {
    return {
      myInput: "input value",
      privacy: false,
      contact: false,

      anlagenOptions: [
        {
          label: "Erzeugungsanlage",
          value: "Erzeugungsanlage"
        },
        {
          label: "Bezugsanlage",
          value: "Bezugsanlage"
        },
        {
          label: "Mischanlage",
          value: "Mischanlage"
        }
      ],
      kurzschlussmelder: [
        {
          label: "Bauseits vorhanden",
          value: "Bauseits vorhanden"
        },
        {
          label: "keine Kurzschlussmelder",
          value: "keine Kurzschlussmelder"
        }
      ],
      hersteller: [
        {
          label: "Horstmann",
          value: "Horstmann"
        },
        {
          label: "Kriess",
          value: "Kriess"
        },
      ],
      kommunikationsprotokoll: [
        {
          label: "ModbusRTU",
          value: "ModbusRTU"
        },
        {
          label: "ModbusTCP",
          value: "ModbusTCP"
        },
        {
          label: "Analog verdrahtet",
          value: "Analog verdrahtet"
        }
      ],
      lsAnsteuerung: [
        {
          label: "Über Kurzschlussmelder",
          value: "Über Kurzschlussmelder"
        },
        {
          label: "Über Fernwirkeinheit",
          value: "Über Fernwirkeinheit"
        }
      ],
      ezaRegler: [
        {
          label: "Bauseits vorhanden",
          value: "Bauseits vorhanden"
        },
        {
          label: "Bauseits beigestellt",
          value: "Bauseits beigestellt"
        },
        {
          label: "Durch Phoenix Contact zu liefern",
          value: "Durch Phoenix Contact zu liefern"
        },
      ],
      hilfsSpannung: [
        {
          label: "Wird bauseits gepuffert zur Verfügung gestellt",
          value: "Wird bauseits gepuffert zur Verfügung gestellt"
        },
        {
          label: "Wird im Schrank produziert und gepuffert",
          value: "Wird im Schrank produziert und gepuffert"
        },
        {
          label: "Wird im Schrank produziert und gepuffert und für extern zur Verfügung gestellt",
          value: "Wird im Schrank produziert und gepuffert und für extern zur Verfügung gestellt"
        },
      ],
      typ: [
        {
          label: "Messgerät mit Display",
          value: "Messgerät mit Display"
        },
        {
          label: "Messgerät ohne Display",
          value: "Messgerät ohne Display"
        }
      ],
      ezaSchutzeinrichtung: [
        {
          label: "Beistellung",
          value: "Beistellung"
        },
        {
          label: "Durch Phoenix Contact zu liefern",
          value: "Durch Phoenix Contact zu liefern"
        }
      ],
      einbauort: [
        {
          label: "MS-Anlage",
          value: "MS-Anlage"
        },
        {
          label: "Fernwirk- und Schutzschrank",
          value: "Fernwirk- und Schutzschrank"
        }
      ],
      kommunikationsprotokoll2: [
        {
          label: "IEC 60870-5-103",
          value: "IEC 60870-5-103"
        },
        {
          label: "ModbusRTU",
          value: "ModbusRTU"
        }
      ],
      modem: [
        {
          label: "Beistellung durch den AG",
          value: "Beistellung durch den AG"
        },
        {
          label: "Wird durch Phoenix Contact geliefert",
          value: "Wird durch Phoenix Contact geliefert"
        },
        {
          label: "Muss nicht Einbau berücksichtigt werden",
          value: "Muss nicht Einbau berücksichtigt werden"
        }
      ],
      hardwarelieferung: [
      {
        label: "Vollständiger Schaltschrank",
        value: "Vollständiger Schaltschrank"
      },
      {
        label: "Einzelne Komponenten",
        value: "Einzelne Komponenten"
      }
    ],
    }
  },

  methods: {

  }
})
export default class HomeView extends Vue {
  @Provide() project = {
    "project": {
      "name": "",
      "system_type": "",
      "travo_power": null,
      "grid_area": "",
      "postal_code": null,
      "offer_date": null,
      "realisation_date": null
    },
    "customer": {
      "company": "",
      "firstname": "",
      "surname": "",
      "mail": "",
    },
    "short_circuit_indicator": {
      "information": "",
      "manufacturer": "",
      "system_type": "",
      "protocol": "",
      "control": "",
    },
    "eza": {
      "information": "",
      "manufacturer": "",
      "type": "",
      "protocol": ""
    },
    "auxiliary_power": {
      "information": "",
      "buffer_time": null
    },
    "low_voltage_measurement": {
      "information": "",
      "manufacturer": "",
      "type": ""
    },
    "eza_protector": {
      "information": "",
      "protective_function": {
        "uf": false,
        "umz": false,
        "qu": false
      },
      "location": "",
      "manufacturer": "",
      "type": "",
      "protocol": ""
    },
    "grid_control_center": {
      "modem": "",
      "manufacturer": "",
      "type": ""
    },
    "services": {
      "eza": {
        "parameterisation": false,
        "commissioning": false
      },
      "eze": {
        "parameterisation": false,
        "commissioning": false
      },
      "short_circuit_indicator": {
        "parameterisation": false,
        "commissioning": false
      },
      "telecontrol": {
        "parameterisation": false,
        "commissioning": false
      },
      "eza_regulator": {
        "parameterisation": false,
        "commissioning": false
      },
      "converter": {
        "commissioning": false
      }
    },
    "delivery": "",
    "miscellaneous": ""
  }
  @Provide() status = {
    responseStatus: 0,
    send: false
  }


  async send() {
    this.status.send = true;
    const url = `${window.location.origin}/api/v1/project`;
    const options = {
      method: "PUT",
      body: JSON.stringify(this.project)
    }

    try {
      let response = await fetch(url, options);
      console.log("success")
      this.status.responseStatus = response.status;
      this.status.send = false;
      window.scrollTo({top: 0, behavior: "smooth"})
    } catch( exception ) {
      this.status.responseStatus = -1;
      this.status.send = false;
      console.error({exception});
      window.scrollTo({top: 0, behavior: "smooth"})
    }
  }
}

</script>


<style>

.table-responsive {
  overflow-x: scroll !important;
}

.table-responsive .table {
  max-width: 100%;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.row {
  vertical-align: middle;
}

.submit-btn {
  display: flex;
  align-items: center;
}

.submit-btn .cr-loading-spinner-v4-2-0 {
  margin-right: 16px;
}
</style>
