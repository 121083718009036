<template>
  <div id="app" class="container-fluid">
    <!--
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    -->
    <div class="container">
      <router-view/>
    </div>


    <footer class="mt-5 py-5 text-center">
      <CrTitle title="Ihr Kontakt zu uns" :heading="4"></CrTitle>
      <p class="footer-address pt-3 px-3">PHOENIX CONTACT Deutschland GmbH, Flachsmarktstraße 8, 32825 Blomberg</p>
      <div class="footer-actions">
        <a target="_blank" href="https://www.phoenixcontact.com/customer/askquestion?&_locale=de-DE&_realm=de" class="cr-contact-footer__formlink mx-5 d-inline-block">
          <span class="ico-datasheet"></span>
          <span>Jetzt Kontakt aufnehmen</span>
        </a>

        <a href="tel:+49 5235 312 900" class="cr-contact-footer__phone mx-5 d-inline-block" itemprop="telephone">
          <div class="ico-telephone"></div>
          <!-- <img src="phone.svg" alt=""> -->
          <span>+49 5235 312 900</span>
        </a>

        <a href="mailto:kundenanschlussloesung@phoenixcontact.de" class="cr-contact-footer__email mx-5 d-inline-block" itemprop="email">
          <span class="ico-mail"></span>
          <span>kundenanschlussloesung@phoenixcontact.de</span>
        </a>
      </div>
    </footer>

    <!--
    <div class="cr-footer-area-container" role="complementary">
      <div class="container-fluid cr-content-module-container cr-contact-footer cr-contact-footer--v2" itemprop="contactPoint" itemscope="" itemtype="http://schema.org/Person" data-cr-gcid="dafqi">

        <div class="cr-contact-footer__name" itemprop="name">Ihr Kontakt zu uns</div>

        <div class="cr-contact-footer__position" itemprop="jobTitle"></div>
        <div class="cr-contact-footer__address" itemprop="address" itemscope="" itemtype="http://schema.org/PostalAddress">PHOENIX CONTACT Deutschland GmbH, Flachsmarktstraße 8, 32825 Blomberg</div>
        <div class="cr-contact-footer__actions">
          <a target="_blank" href="/customer/askquestion?&amp;_locale=de-DE&amp;_realm=de" class="cr-contact-footer__formlink">
            <span class="ico-datasheet"></span>
            <span>Jetzt Kontakt aufnehmen</span>
          </a>
          <a href="tel:+49 5235 312 900" class="cr-contact-footer__phone" itemprop="telephone">
            <span class="ico-telephone"></span>
            <span>+49 5235 312 900</span>
          </a>
          <a href="mailto:kundenanschlussloesung@phoenixcontact.de" class="cr-contact-footer__email" itemprop="email">
            <span class="ico-mail"></span>
            <span>energy@phoenixcontact.de</span>
          </a>
        </div>
      </div>
    </div>
    -->
  </div>
</template>

<script>

import {CrTitle} from "@pxc/crisp-oss-ui-components";

export default {
  name: "App",
  components: {
    CrTitle
  }
}
</script>

<style lang="scss">

* {
  font-family: "Source Sans Pro", sans-serif !important;
}

footer {
  background: #edf0f3 !important;
  text-align: center !important;
}

.footer-address {
  font-size: 1rem;
}

.footer-actions a,.footer-actions a:text-dec {
  color: #007C84 !important;
}

.footer-actions img {
  height: 16px;
  width: 16px;
  color: #0098a1 !important;
}

.footer-actions .ico-telephone {
  margin-bottom: -4px;
  margin-right: 12px;
  display: inline-block;
  height: 16px;
  width: 16px;
  -webkit-mask: url("~/public/phone.svg") no-repeat center;
  mask: url("~/public/phone.svg") no-repeat center;
  background-color: #0098a1;
}

.footer-actions .ico-mail {
  margin-bottom: -4px;
  margin-right: 12px;
  display: inline-block;
  height: 16px;
  width: 16px;
  -webkit-mask: url("~/public/envelope.svg") no-repeat center;
  mask: url("~/public/envelope.svg") no-repeat center;
  background-color: #0098a1;
}

.footer-actions .ico-datasheet {
  margin-bottom: -4px;
  margin-right: 12px;
  display: inline-block;
  height: 16px;
  width: 16px;
  -webkit-mask: url("~/public/file-lines.svg") no-repeat center;
  mask: url("~/public/file-lines.svg") no-repeat center;
  background-color: #0098a1;
}

/*
[class*=" ico-"], [class^=ico-] {
  font-family: phoenix-contact-icons!important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-datasheet:before {
  content: "\E941";
}
*/

a {
  font-weight: 600 !important;
  color: #0098a1 !important;
  text-decoration: none !important;
  background-color: transparent !important;
  white-space: nowrap;
}
</style>
