import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "@fontsource/source-sans-pro"
import "bootstrap/dist/css/bootstrap-grid.css"
import "bootstrap/dist/css/bootstrap-utilities.css"
import "bootstrap/dist/css/bootstrap-reboot.css"
import "@pxc/crisp-oss-ui-components/dist/crisp-ui-components.css"

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
